
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onBeforeMount,
  getCurrentInstance,
  ComponentInternalInstance,
  createVNode,
  watch,
} from "vue";
import pagaTable from "@/components/pagination-table/index";
import formSearch from "@/components/page-search/form";
import popupWindow from "./popupNew.vue";
import specification from "./specification.vue";
import {
  page,
  GoodsSpecController_del,
} from "@/api/commodityManagement/goodsspec";
import { message } from "ant-design-vue";
const purchaseColumns = [
  {
    title: "规格名称",
    dataIndex: "specName",
  },
  {
    title: "创建时间",
    dataIndex: "createdTime",
  },
  {
    title: "更新时间",
    dataIndex: "updatedTime",
  },
  {
    title: "操作",
    dataIndex: "operation",
    slots: { customRender: "operation" },
  },
];
// const replaceFields = {
//   children: "children",
//   title: "menuName",
//   key: "menuId",
// };
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    popupWindow,
    formSearch,
    specification,
  },
  setup(props: any) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance;
    // const treeData = ref<TreeDataItem[]>([]);
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
    });
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        const params_api = {
          currentPage: params.pageIndex,
          pageSize: params.pageSize,
        };
        page(params_api).then((res) => {
          reslove(res);
        });
      });
    };
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      console.log(pagination, filter, sorter, currentDataSource);
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "编辑";
      popupWindow.value.showModal(row);
    };
    const specificationClick = (text: string, row: any) => {
      console.log(row, "规格列表");
      // specificationVisible.value = true;
      specificationTile.value = `规格值列表，所属规格:${row.specName}`;
      specificationRef.value.showModal(row);
    };
    const onClose = () => {
      specificationVisible.value = false;
    };
    const formSearch = ref();
    const popupWindow = ref();
    const specificationRef = ref();
    const popupRandom = ref();
    const specificationTile = ref();
    const specificationVisible = ref<boolean>(false);
    const Title = ref();
    const updatedTime = ref([]);
    const handleAdd = () => {
      Title.value = "新增";
      popupWindow.value.showModal();
    };
    const handleSearch = (formState: any) => {
      // formSearch.value.getQuery();
      // console.log({ ...formSearch.value.getQuery() });
      // console.log("插槽", { ...updatedTime.value });
      table.value.refresh();
    };
    const refresh = () => {
      table.value.refresh();
      data.selectedRowKeys = [];
    };

    const visible = ref<boolean>(false);
    const questions = ref([] as any[]);
    const del = (val: any) => {
      GoodsSpecController_del({ id: val.goodsSpecId }).then((res) => {
        table.value.refresh();
        message.success("操作成功");
      });
    };
    const roleId = ref();
    return {
      onClose,
      specificationTile,
      specificationClick,
      specificationVisible,
      questions,
      visible,
      del,
      refresh,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      specificationRef,
      popupRandom,
      Title,
      handleAdd,
      handleSearch,
      updatedTime,
      // success
    };
  },
});

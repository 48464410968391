<template>
  <div>
    <a-modal width="70%" :title="specificationTile" :visible="visible" :ok-button-props="{ danger: true }" :confirm-loading="confirmLoading" footer @ok="handleOk" @cancel="cancel">
      <!-- <a-form ref="formRef" :model="formState" :rules="rules">
        <a-form-item label="名称：" goodsspecvalue="goodsspecvalue">
          <a-input v-model:value="formState.goodsspecvalue" style="width: 370px" />
        </a-form-item>
      </a-form> -->
      <pagaTable ref="table" :columns="purchaseColumns" :sourceData="getDataApi" row-key="goodsSpecId" @change="handleChange">
        <template #headerLeft>
          <a-button type="default" class="ant-btn-add" @click="handleAdd">
            新增
          </a-button>
        </template>
        <template #operation="{ text, record }">
          <!-- <div v-if="record.roleName !== '安全员'"> -->
          <a-button type="link" @click="handleClick(text, record)">编辑</a-button>
          <!-- <a-button type="link" @click="authorization(record)">授权</a-button> -->
          <a-popconfirm title="确定删除吗" ok-text="是的" cancel-text="关闭" @confirm="del(record)">
            <a-button type="link">删除</a-button>
          </a-popconfirm>
          <!-- </div> -->
        </template>
      </pagaTable>
    </a-modal>
    <Form ref="popupWindow" :Title="Title" @refresh="refresh"></Form>
  </div>
</template>
<script>
import Form from './form.vue'
import { defineComponent } from 'vue'
import pagaTable from '@/components/pagination-table/index'
import {
  GoodsSpecValueController_page,
  GoodsSpecValueController_del
} from '@/api/commodityManagement/goodsspec'
const form = {
  goodsSpecValue: '',
  goodsSpecId: ''
}
export default defineComponent({
  goodsSpecValue: 'index',
  props: {
    specificationTile: {
      type: String,
      required: false
    },
    FormData: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      visible: false,
      Title: '',
      formState: {
        ...form
      },
      purchaseColumns: [
        {
          title: '规格值',
          dataIndex: 'goodsSpecValue'
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime'
        },
        {
          title: '更新时间',
          dataIndex: 'updatedTime'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: { customRender: 'operation' }
        }
      ]
    }
  },
  components: {
    pagaTable,
    Form
  },
  created() {},
  methods: {
    handleAdd() {
      this.Title = '新增'
      this.$refs.popupWindow.showModal(this.formState.goodsSpecId)
    },
    handleClick(text, row) {
      this.Title = '编辑'
      this.$refs.popupWindow.showModal(row)
    },
    refresh() {
      this.$refs.table.refresh()
    },
    getDataApi(params) {
      return new Promise((reslove) => {
        console.log(this.formState)
        const params_api = {
          currentPage: params.pageIndex,
          pageSize: params.pageSize,
          goodsSpecId: this.formState.goodsSpecId
        }
        GoodsSpecValueController_page(params_api).then((res) => {
          reslove(res)
        })
      })
    },
    del(val) {
      GoodsSpecValueController_del({ id: val.goodsSpecValueId }).then((res) => {
        this.refresh()
        this.$message.success('操作成功')
      })
    },
    cancel() {
      this.formState = { ...form }
      this.visible = false
    },
    showModal(val) {
      this.visible = true
      this.formState = { ...form, ...val }
      this.refresh()
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
